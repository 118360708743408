import React from "react"
import LogoElo from "../images/logo-elo.svg"
import LogoMehilainen from "../images/logo-mehilainen.svg"
import AKlinikka from "../images/logo-a-klinikka.svg"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Footer = () => {
  const companies = [
    {
      title: "A-klinikka",
      img: AKlinikka,
      url: "https://www.a-klinikka.fi/",
    },
    {
      title: "Mehiläinen",
      img: LogoMehilainen,
      url: "https://www.mehilainen.fi/",
    },
    {
      title: "Työeläkeyhtiö Elo",
      img: LogoElo,
      url:
        "https://www.elo.fi/?utm_source=website&utm_medium=banner&utm_campaign=neuvoa-antavat.fi",
    },
  ]
  return (
    <footer className="py-8 space-y-8 text-center bg-gray-900 md:py-20">
      <h3 className="text-sm font-bold tracking-widest text-gray-400 uppercase">
        Yhteistyössä
      </h3>
      <ul className="justify-center mx-auto space-y-4 sm:space-y-0 sm:flex sm:space-x-12">
        {companies.map(company => (
          <li className="text-center" key={company.title}>
            <a
              href={company.url}
              className="block group"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={company.img}
                style={{ height: "36px" }}
                className="mx-auto mb-2"
                loading="lazy"
                alt={`Yrityksen ${company.title} logo`}
              />
              <p className="text-sm text-gray-400 group-hover:text-white md:text-base">
                {company.title}
              </p>
            </a>
          </li>
        ))}
      </ul>
      <p className="text-xs tracking-widest text-gray-400 uppercase">
        Copyright &copy;{" "}
        <OutboundLink
          href="https://lifeclass.fi/"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-gray-300"
        >
          Lifeclass
        </OutboundLink>
      </p>
    </footer>
  )
}

export default Footer
