import React from "react"
import logo from "../images/logo-neuvoa-antavat.svg"
import { Link } from "gatsby"
import Image from "gatsby-image"
import InlineVideo from "../components/InlineVideo"
import heroVideo from "../images/video-hero.mp4"

const Hero = ({ title, description, category, isFront, poster }) => {
  const headerClasses = `${
    isFront
      ? "hero-small md:hero-large xl:hero-huge"
      : "pt-20 md:pt-32 pb-12 md:pb-20"
  }`
  return (
    <header
      className={`relative flex items-center justify-center overflow-hidden ${headerClasses}`}
    >
      {/* Top bar */}
      <div className="fixed top-0 left-0 right-0 z-40 flex py-4 md:py-6">
        <div className="container">
          <div className="row">
            <div className="col">
              <Link to="/" className="block">
                <img className="w-48" src={logo} alt="Neuvoa-antavat.fi logo" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Main info */}
      {isFront ? (
        <div className="container">
          <div className="max-w-lg row">
            <div className="relative z-20 col">
              <p className="mb-2 text-sm font-bold tracking-widest text-white uppercase opacity-50 md:mb-4">
                Nyt nousussa
              </p>
              <h1 className="mb-2 text-3xl font-bold text-white md:mb-4 md:text-4xl lg:text-5xl lg:leading-comfy">
                {title}
              </h1>
              {description && (
                <p className="pb-4 leading-snug text-gray-400 md:text-xl">
                  {description}
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        // Subpages
        <div className="container">
          <div className="row">
            <div className="relative z-20 max-w-2xl col">
              <h1 className="mb-2 text-3xl font-bold text-white md:mb-4 leading-comfy md:text-4xl lg:text-5xl lg:leading-comfy">
                {title}
              </h1>
              {description && (
                <p className="pb-4 leading-snug text-gray-400 md:text-xl">
                  {description}
                </p>
              )}
              {category && (
                <Link
                  to={`/${category.slug}/`}
                  className="relative z-20 inline-block px-3 py-1 mt-4 text-sm text-yellow-200 bg-yellow-500 bg-opacity-25 rounded-lg hover:bg-yellow-500 hover:bg-opacity-100 hover:text-black"
                >
                  <span className="text-xs uppercase opacity-50">
                    Kategoria:{" "}
                  </span>
                  {category.shortTitle}
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
      {/* Background image */}
      {poster && (
        <div className="absolute top-0 bottom-0 left-0 right-0">
          <div className="absolute bottom-0 left-0 right-0 z-10 h-64 bg-gradient-to-t from-black"></div>
          <Image
            fluid={poster.fluid}
            className="opacity-75"
            style={{ filter: `blur(100px)` }}
          />
        </div>
      )}
      {/* Background video */}
      {isFront && (
        <div className="absolute w-full h-full">
          {/* Bottom gradient overlay */}
          <div className="absolute bottom-0 left-0 right-0 z-10 h-64 bg-gradient-to-t from-black"></div>
          {/* Dark overlay */}
          <div className="absolute top-0 bottom-0 left-0 right-0 z-10 bg-black opacity-50 top"></div>
          {/* Background video */}
          <InlineVideo
            src={heroVideo}
            className="absolute video-small md:video-large"
            style={{
              left: "0",
              right: "0",
              top: "0",
              bottom: "0",
              maxWidth: "initial",
            }}
          ></InlineVideo>
        </div>
      )}
    </header>
  )
}

export default Hero
