import React, { useRef, useEffect } from "react"

const InlineVideo = ({ src, className, style }) => {
  const videoRef = useRef(null)

  useEffect(() => {
    const { current: videoElement } = videoRef
    videoElement.setAttribute("muted", "")
  }, [])

  return (
    <video
      src={src}
      ref={videoRef}
      style={style}
      className={className}
      autoPlay
      playsInline
      muted
      loop
    ></video>
  )
}

export default InlineVideo
