import React from "react"
import Footer from "../components/Footer"
import Hero from "../components/Hero"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

const Layout = ({ children, pageData }) => {
  const { title, description, category, isFront, poster, ogImage } = pageData
  const data = useStaticQuery(graphql`
    {
      ogImage: contentfulOgImage {
        image {
          fixed(width: 1200, height: 630, quality: 75) {
            src
          }
        }
      }
    }
  `)
  const defaultOgImage = `https:${data.ogImage.image.fixed.src}`
  const realOgImage = ogImage ? ogImage : defaultOgImage
  const realTitle = `${
    isFront ? `Neuvoa-antavat.fi` : `${title} | Neuvoa-antavat.fi`
  }`
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{realTitle}</title>
        {description && <meta name="description" content={description} />}
        {/* OG */}
        <meta property="og:locale" content="fi_FI" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={realTitle} />
        <meta property="og:site_name" content="Neuvoa-antavat.fi" />
        {description && (
          <meta property="og:description" content={description} />
        )}
        <meta property="og:image" content={realOgImage} />
        <meta name="twitter:site" content="Neuvoa-antavat.fi" />
        <meta name="twitter:card" content="summary_large_image" />
        <html lang="fi" />
      </Helmet>
      <Hero
        title={title}
        description={description}
        category={category}
        isFront={isFront}
        poster={poster}
      />
      <main className="container mb-12 md:mb-20">{children}</main>
      <Footer />
    </>
  )
}

export default Layout
